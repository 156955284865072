/* This example requires Tailwind CSS v2.0+ */
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function Example() {
  return (
    <div className="bg-white">
      <main>
        <div>
          {/* Hero card */}
          <div className="relative">
            <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
              <div className="relative shadow-xl sm:overflow-hidden sm:rounded-2xl">
                <div className="absolute inset-0">
                  <StaticImage
                    src="../images/gavel.jpg"
                    quality={95}
                    formats={['AUTO', 'WEBP', 'AVIF']}
                    alt="2 Percent Commission"
                    className="h-full w-full object-cover"
                  />

                  <div className="absolute inset-0 bg-gray-700 mix-blend-multiply" />
                </div>
                <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                  <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                    <span className="block text-white">Cosens</span>
                    <span className="block text-white">
                      Auctions and Appraisals
                    </span>
                    <span className="block text-4xl text-brand-red-800">
                      Since 1969
                    </span>
                  </h1>
                  <p className="mx-auto mt-6 max-w-lg text-center text-xl text-gray-200 sm:max-w-3xl">
                    Your online estate and real estate auction specialists. Over
                    50 years experience in conducting appraisals, performing
                    live and online auctions and facilitating the re-homing of
                    your precious belongings.
                  </p>

                  <div className="mx-auto mt-10 max-w-sm sm:flex sm:max-w-none sm:justify-center">
                    <div className="space-y-4 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5 sm:space-y-0">
                      <a
                        href="https://cosensauctions.hibid.com/auctions/"
                        className="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-3 text-base font-medium text-brand-red-800 shadow-sm hover:bg-brand-red-900 hover:text-gray-50 sm:px-8"
                      >
                        View Current Auctions
                      </a>
                      <a
                        href="tel:519-282-8044"
                        className="flex items-center justify-center rounded-md border border-transparent bg-brand-red-800 bg-opacity-60 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-opacity-70 sm:px-8"
                      >
                        Call Mark: 519-282-8044
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* More main page content here... */}
      </main>
    </div>
  );
}
