import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';
import Seo from '../components/seo';
import Hero from '../components/hero';
import RealEstate from '../components/realestate';
import About from '../components/about';
import CTAFree from '../components/cta-free';

export default function Contact() {
  return (
    <Layout>
      <Seo
        title="Online Estate and Real Estate Auction Specialists"
        description="Over 50 years experience in conducting appraisals, performing live and online auctions and facilitating the re-homing of your precious belongings."
      />
      <Hero />
      <CTAFree />
      <About />
      <RealEstate />
      <div className="relative lg:py-24">
        <div className="lg:mx-auto lg:grid lg:max-w-6xl lg:grid-cols-2 lg:items-center lg:gap-24 lg:px-8">
          <div className=" relative mx-auto h-full text-center lg:pt-20">
            <StaticImage
              src="../images/2percent.png"
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="2 Percent Commission"
            />
          </div>
          <div className="relative  mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
            {/* Content area */}

            <div className="pt-12 sm:pt-16 lg:pt-20">
              <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Why is that number so big?
              </h2>
              <div className="mt-6 space-y-6 text-gray-500">
                <p className="text-lg leading-7">
                  2% is the commission charged at Cosens Auctions & Appraisals
                  for all of our Real Estate auctions.
                </p>
                <p className="text-lg leading-7">
                  Have an Estate you need to deal with but you don’t have the
                  time or energy?
                </p>
                <p className="text-lg leading-7">
                  {' '}
                  Relocating but can’t take everything with you?
                </p>
                <p className="text-lg leading-7">
                  Downsizing and it’s time to let some things go?
                </p>
                <p className="text-lg leading-7">Let us help you!</p>
                <p className="text-lg leading-7">
                  Skip the yard sales, Kijiji and Marketplace.
                </p>
                <p className="text-lg leading-7">
                  Call Cosens Auctions and Appraisal’s first.
                </p>
                <p className="text-lg leading-7">
                  We don’t just auction the home, we get it ready for sale,
                  including cleanup. The online auction wouldn’t just be the
                  home, it could be the furniture, collectibles, vehicles,
                  Bric-a-Brac, etc.
                </p>
                <p className="text-lg leading-7">
                  It doesn’t matter how much or how little, everything goes!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CTAFree />
    </Layout>
  );
}
