import React from 'react';

export default function CTAFree() {
  return (
    <div className="">
      <div className="mx-auto max-w-6xl py-16 px-4 text-center sm:py-20 sm:px-6 lg:px-8">
        <h2 className="text-brand-gray-900 text-3xl font-extrabold sm:text-4xl">
          <span className="block">Call Mark to come out for a</span>
          <span className="block">free consultation and written proposal.</span>
        </h2>

        <a
          href="tel:519-282-8044"
          className="mt-8 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-brand-red-900 px-5 py-3 text-base font-medium text-gray-100  sm:w-auto"
        >
          Get Started Today
        </a>
      </div>
    </div>
  );
}
